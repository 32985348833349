<div class="m-3">
    <!-- Title -->
    <p class="labels">
        {{ field.label }}
        <span *ngIf="fieldRequired()" class="red">*</span>
    </p>

    <div [ngClass]="{'view-mode': pageMode === FormMode.VIEW, 'edit-mode': pageMode === FormMode.EDIT}">
        <!-- Main content -->
        <ng-container *ngIf="cellType === FormCellType.PREVIOUS; else currentField">
            <p class="value">{{ getValueOrDefault("-") }}</p>
        </ng-container>

        <ng-template #currentField [ngSwitch]="viewMode">
            <ng-container *ngSwitchCase="FormViewModeEnum.EDIT_RESULTS">
                <input
                    [ngClass]="fieldForm?.touched && fieldForm?.hasError('required') ? 'required' : ''"
                    nbInput
                    fullWidth
                    [formControl]="fieldForm"
                    type="text"
                    (input)="validateInput($event)"
                />

                <ngx-validation-message
                    label="Value"
                    [showMax]="fieldForm?.hasError('max')"
                    [showMin]="fieldForm?.hasError('min')"
                    [max]="maxValue"
                    [min]="minValue"
                ></ngx-validation-message>
            </ng-container>

            <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_RESULTS">
                <ng-container>
                    <p class="value">{{ getValueOrDefault("-") }}</p>
                </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_CONFIG">
                <input nbInput fullWidth class="disable-btn" />
            </ng-container>
        </ng-template>
    </div>
</div>
