// Configuration settings for Multi-tenant Production
export const environment = {
    APP_VERSION_MAJOR: 2,
    APP_VERSION_MINOR: 8,
    APP_VERSION_PATCH: 0,

    PRODUCTION: true,
    STAGING: false,

    NODE_BASE_URL: 'https://api-node.us.engincloud.com',
    NODE_API_URL: 'https://api-node.us.engincloud.com/api',
    PYTHON_API_URL: '',

    OAUTH_CLIENT_ID: '23GUXyuXcRPO0Rc4icRVpu1dkksf5PMtLCVZf6DX',
    OAUTH_CLIENT_SECRET: 'mfYBrHr1R3SNSBgVpNs68qb8NwQ8d4mzPZD7JM5Z',

    SSO_ENABLED: false,
    AZURE_AD: {
        AUTHORITY: '',
        APPLICATION_ID: '',
        REDIRECT_URL: '',
    },
    SENSITIVE_INFO_LOGIN: false,
    SENSITIVE_INFO_HEADER: false,
};
